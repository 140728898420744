export default function NextArrow(props) {
  const { className, style, onClick } = props;

  return (
    <>
      <svg
        className={className}
        onClick={onClick}
        height="28"
        width="24"
        fill="none"
        viewBox="0 0 24 28"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          boxSizing: "border-box",
          WebkitFontSmoothing: "antialiased",
          height: "25px",
          width: "25px",
          cursor: "pointer",
        }}
      >
        <path
          d="M0.46733 27.5717L23.8356 14.0789C23.8854 14.0517 23.9269 14.0115 23.9558 13.9627C23.9847 13.9139 24 13.8582 24 13.8015C24 13.7448 23.9847 13.6891 23.9558 13.6403C23.9269 13.5915 23.8854 13.5513 23.8356 13.5241L0.467331 0.0423732C0.410172 0.00900342 0.343847 -0.00523717 0.278027 0.00172128C0.212206 0.00867972 0.150326 0.0364769 0.101408 0.081061C0.0524902 0.125645 0.0190868 0.184683 0.00606917 0.249578C-0.00694848 0.314472 0.00109671 0.38183 0.0290355 0.441832L5.83782 13.6794C5.85699 13.7192 5.86695 13.7628 5.86695 13.807C5.86695 13.8512 5.85699 13.8948 5.83782 13.9346L0.0290344 27.1722C0.00109554 27.2322 -0.00694966 27.2996 0.00606799 27.3645C0.0190856 27.4294 0.052489 27.4884 0.101407 27.533C0.150325 27.5776 0.212205 27.6054 0.278025 27.6124C0.343846 27.6193 0.41017 27.6051 0.46733 27.5717Z"
          fill="#1571FA"
          style={{
            boxSizing: "border-box",
            WebkitFontSmoothing: "antialiased",
            transition: "fill 0.2s",
          }}
        />
      </svg>
    </>
  );
}
