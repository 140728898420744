import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../styles/testimonials/two.scss";
import { useState } from "react";
import { useMediaQuery } from "@mui/material";
import NextArrow from "../arrows/NextArrow";
import PrevArrow from "../arrows/PrevArrow";

const TestimonialsTwo = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const mobile = useMediaQuery("(max-width:800px)");

  const settingsMobile = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    customPaging: (i) => (
      <div
        style={{
          width: "100%",
          color: "blue",
          position: "relative",
          placeSelf: "center",
        }}
      >
        <div style={{ opacity: "0", cursor: "pointer" }}>{i}</div>
        <span
          style={{
            position: "absolute",
            right: mobile ? "1rem" : "4rem",
            cursor: "pointer",
            // top: "-3.5rem",
            backgroundColor: i === currentSlide ? "#0781fe" : "#343638",
            height: "1.5rem",
            width: "1.5rem",
            borderRadius: "100%",
          }}
        ></span>
      </div>
    ),
  };

  const reviews = [
    // {
    //     name: "Sir Danthel",
    //     image: "https://user-images.trustpilot.com/62f64d015f9959001469c9f1/73x73.png",
    //     title: "Trader",
    //     review: "At this point I'm just in awe at this platform. From the emails keeping in touch and even the guidance. What a wonderful opportunity to help my family and become the earner I know I can be. 5 stars"
    // },
    {
      name: "Louis can Nuekerk",
      image:
        "https://user-images.trustpilot.com/62fb7908e7527700131ee873/73x73.png",
      title: "User",
      review:
        "Incredible company, I've been trading for a while with them, had two quick withrawals and fee refund in less than 48 hours. I absolutely recommend the firm",
    },
    {
      name: "Peter M",
      image:
        "https://user-images.trustpilot.com/630399601be97400121ac68d/73x73.png",
      title: "User",
      review:
        "This is an amazing platform which gives you opportunity to achieve their goals and become successful trade with the support of finances.",
    },
    {
      name: "Jakub Szulc",
      image:
        "https://user-images.trustpilot.com/6304aa40cc230c001374b256/73x73.png",
      title: "Trader",
      review:
        "Great company, no issues with payouts, great customer support. Highly recommend for experienced traders.",
    },
    {
      name: "Jeremy Harper",
      image:
        "https://user-images.trustpilot.com/62f7f8c90a1282001276670b/73x73.png",
      title: "Trader",
      review:
        "Legitimate! Thank you for the opportunity, nothing out there that can compare",
    },
  ];

  return (
    <>
      <div className="testimonials__two__container">
        {/* <div className="testimonials__two__wrapper"> */}
        <Slider
          {...settingsMobile}
          afterChange={(e) => setCurrentSlide(e)}
          className="testimonials__two__wrapper"
        >
          {reviews.map((review) => (
            <div className="testimonials__two__content">
              <h1 className="testimonials__two__review">{review.review}</h1>
              <div className="testimonials__two__user__wrapper">
                <figure className="testimonials__two__figure">
                  <img src={review.image} alt="avatar" />
                </figure>

                <span className="testimonials__two__user__name__wrapper">
                  <p className="testimonials__two__user__name">{review.name}</p>
                  <p className="testimonials__two__user__title">
                    {review.title}
                  </p>
                </span>
              </div>
            </div>
          ))}
        </Slider>
        {/* </div> */}
      </div>
    </>
  );
};

export default TestimonialsTwo;
