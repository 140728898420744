import Header from "./components/Header";
import HeroSix from "./hero/HeroSix";
import FeaturesOne from "./features/FeaturesOne";
import StatsOne from "./stats/StatsOne";
import AssetsTwo from "./assets/AssetsTwo";
import CTAThree from "./cta/CTAThree";
import FooterSeven from "./footer/FooterSeven";
import TestimonialsTwo from "./testimonials/TestimonialsTwo";
import { useEffect } from "react";
import { Timeline } from "react-ts-tradingview-widgets";

const Main = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    function removeTickers() {
      const ticker = document.querySelectorAll("#tradingview_widget_wrapper");
      ticker.forEach((tick) => {
        tick.lastChild.style.display = "none";
      });
    }

    removeTickers();

    return () => {
      removeTickers();
    };
  });

  return (
    <>
      <Header />
      <HeroSix />
      <br />
      <FeaturesOne />
      <StatsOne />
      <Timeline
        colorTheme="dark"
        feedMode="all_symbols"
        // market="crypto"
        height={400}
        width="100%"
      ></Timeline>
      <TestimonialsTwo />
      {/* <AssetsTwo /> */}
      <CTAThree />
      <FooterSeven />
    </>
  );
};

export default Main;
