export default function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <>
      <svg
        className={className}
        onClick={onClick}
        height="28"
        width="24"
        fill="none"
        viewBox="0 0 24 28"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          boxSizing: "border-box",
          WebkitFontSmoothing: "antialiased",
          height: "25px",
          width: "25px",
          cursor: "pointer",
        }}
      >
        <path
          d="M23.5327 27.5717L0.164401 14.0789C0.114634 14.0517 0.0731126 14.0115 0.0441879 13.9627C0.0152632 13.9139 6.05762e-07 13.8582 6.03282e-07 13.8015C6.00803e-07 13.7448 0.0152632 13.6891 0.0441879 13.6403C0.0731126 13.5915 0.114634 13.5513 0.164401 13.5241L23.5327 0.0423732C23.5898 0.00900342 23.6562 -0.00523717 23.722 0.00172128C23.7878 0.00867972 23.8497 0.0364769 23.8986 0.081061C23.9475 0.125645 23.9809 0.184683 23.9939 0.249578C24.0069 0.314472 23.9989 0.38183 23.971 0.441832L18.1622 13.6794C18.143 13.7192 18.1331 13.7628 18.1331 13.807C18.1331 13.8512 18.143 13.8948 18.1622 13.9346L23.971 27.1722C23.9989 27.2322 24.0069 27.2996 23.9939 27.3645C23.9809 27.4294 23.9475 27.4884 23.8986 27.533C23.8497 27.5776 23.7878 27.6054 23.722 27.6124C23.6562 27.6193 23.5898 27.6051 23.5327 27.5717Z"
          fill="#1571FA"
          style={{
            boxSizing: "border-box",
            WebkitFontSmoothing: "antialiased",
            transition: "fill 0.2s",
          }}
        />
      </svg>
    </>
  );
}
